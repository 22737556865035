export function UploadIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      fill="currentColor"
      shapeRendering="geometricPrecision"
      viewBox="0 0 24 24"
      {...props}
    >
      <path d="M11 5V3h2v2h2v2h2v2h-2V7h-2v10h-2V7H9v2H7V7h2V5h2zM3 15v6h18v-6h-2v4H5v-4H3z" />
    </svg>
  );
}
